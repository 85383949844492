<template>
  <Container>
    <section class="grid md:grid-cols-2 md:grid-rows-2 gap-2 mt-16 mb-16">
      <div class="bg-gray-200 aspect-square row-span-2 rounded-lg">
      </div>
      <div class="bg-white row-span-1 rounded-lg flex items-end">
        <h2 class="font-bold text-3xl">Challenge of the day</h2>
      </div>
      <div class="bg-yellow row-span-1 rounded-lg p-4 flex items-end">
        <div>
          <div class="font-bold text-2xl mb-2">Flam Paradiddle</div>
          <p class="text-base">Lorem ipsum dolor sit amet consectetur. Eleifend nullam aliquam tristique mauris ante ut consectetur tortor sed.</p>
        </div>
      </div>
    </section>
  </Container>

</template>

<script setup>

</script>